import React, { useEffect, useRef, useState } from 'react';

// import weapp from './assets/weapp.svg';
// import qrcode from './assets/qrcode.jpg';

import name from '../../assets/name.svg';
import Android from '../../assets/Android.svg';
import iOS from '../../assets/iOS.svg';
import './index.css';

interface IProps {
  className: string;
}

interface Pos {
  top: number;
  left: number;
}

export default function ({ className }: IProps) {
  const [showAndroid, setShowAndroid] = useState(false);
  const [showIOS, setShowIOS] = useState(false);
  const [androidPos, setAndroidPos] = useState<Pos>();
  const [iOSPos, setIOSPos] = useState<Pos>();

  const androidBtn = useRef<HTMLButtonElement>(null)
  const iosBtn = useRef<HTMLButtonElement>(null)

  const show = (type: string) => {
    if (type === 'android') {
      setShowAndroid(true);
      setShowIOS(false);
    } else if (type === 'ios') {
      setShowAndroid(false);
      setShowIOS(true);
    }
  }

  const off = () => {
    setShowAndroid(false);
    setShowIOS(false);
  }

  useEffect(() => {
    if (androidBtn.current) {
      const android = androidBtn.current;
      setAndroidPos({
        top: android.offsetTop - 145,
        left: android.offsetLeft
      });
    }

    if (iosBtn.current) {
      const ios = iosBtn.current;
      setIOSPos({
        top: ios.offsetTop - 145,
        left: ios.offsetLeft
      });
    }
  }, [androidBtn.current, iosBtn.current])

  return (
    <div className={className}>
      <div className='download-box'>
        <div><img src={name} /></div>
        <div className='text-[24px] m-2 tracking-[4px]'>一张照片，生成魅力写真</div>
        <div className='mt-[20px]'>
          <button name='ios' ref={iosBtn} className='download-btn mr-[20px] text-[14px]' onMouseEnter={() => { show('ios') }} onMouseLeave={off}>
            <img src={iOS} className='w-[20px] inline mr-1' />iOS下载
          </button>
          <div className='qrcode shadow-lg' style={{ display: showIOS ? 'flex' : 'none', top: iOSPos?.top, left: iOSPos?.left }}>
            <div>开发中……<br />敬请期待</div>
          </div>
          <button name='android' ref={androidBtn} className='download-btn text-[14px]' onMouseEnter={() => { show('android') }} onMouseLeave={off}>
            <img src={Android} className='w-[20px] inline mr-1' />Android下载
          </button>
          <div className='qrcode shadow-lg' style={{ display: showAndroid ? 'flex' : 'none', top: androidPos?.top, left: androidPos?.left }}>
            开发中……<br />敬请期待
          </div>
        </div>
      </div>
    </div>
  );
}