import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import Download from '../../components/Download';

import logo from '../../assets/logo-rect.svg';
import name from '../../assets/name.svg';
import icp from '../../assets/icp.png';

import './index.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Link } from 'react-router-dom';

function App() {
  return (
    <div>
      <div className="bg-[#FF8974] flex justify-around items-center">
        <div className='flex items-center'>
          <img src={logo} className="h-[35px] m-4 inline" />
          <img src={name} className='h-[30px] inline' />
        </div>
        <span className="w-40 mr-40 inline-flex justify-between">
          <span>首页</span>
          <span><Link to='/about'>关于我们</Link></span>
        </span>
      </div>

      <div className='relative'>
        <Swiper
          modules={[Pagination, Autoplay]}
          pagination={true}
          loop={true}
          autoplay={{ delay: 3000 }}
        >
          <SwiperSlide key={1}>
            <img className='w-full' src="images/b1.png" />
          </SwiperSlide>
          <SwiperSlide key={2}>
            <img className='w-full' src="images/b2.png"/>
          </SwiperSlide>
          <SwiperSlide key={3}>
            <img className='w-full' src="images/b3.png"/>
          </SwiperSlide>
          <SwiperSlide key={4}>
            <img className='w-full' src="images/b4.png"/>
          </SwiperSlide>
        </Swiper>
        <Download className='absolute top-[250px] left-[400px] z-50'/>
      </div>

      <div className='mt-[60px]'>
        <div className='flex justify-center text-5xl font-sans'>荔枝相机<span className='text-[#FF8974]'>独特优势</span></div>
        <div className='flex justify-center items-center m-2'>简单<span className='dot m-2'/>随心<span className='dot m-2'/>自由<span className='dot m-2'/>快速</div>
      </div>

      <div className='desc'>
        <div><img src="images/a1.jpg" /></div>
        <div>
          <div className='desc-title'>生成写真<span className='text-[#FF8974]'>仅需一张头像</span></div>
          <div className='desc-detail'>使用创新AI技术，免除多张照片才能建立数字分身的要求，<br />一张头像即可生成魅力写真！</div>
        </div>
      </div>

      <div className='desc'>
        <div>
          <div className='desc-title'>海量风格<span className='text-[#FF8974]'>自由选择</span></div>
          <div className='desc-detail'>古装汉服、唯美、赛博朋克、科幻、都市潮流，应有尽有<br/>每周定时更新模板，美丽不间断！</div>
        </div>
        <div><img className='float-right' src="images/a2.jpg" /></div>
      </div>

      <div className='desc'>
        <div><img className='' src="images/a3.jpg" /></div>
        <div>
          <div className='desc-title'>拍照姿势<span className='text-[#FF8974]'>随意切换</span></div>
          <div className='desc-detail'>相同风格，不同姿势、角度，还可以自定义姿势<br />美，从来都与众不同！</div>
        </div>
      </div>

      <div className='desc'>
        <div>
          <div className='desc-title'>真AI生成<span className='text-[#FF8974]'>形像多变</span></div>
          <div className='desc-detail'>真实AIGC技术应用，所有写真都是由AI生成<br />每一张都拥有独一无二的美！</div>
        </div>
        <div><img className='float-right' src="images/a4.jpg" /></div>
      </div>

      <div className='desc'>
        <div><img className='' src="images/a5.jpg" /></div>
        <div>
          <div className='desc-title'>魅力写真<span className='text-[#FF8974]'>快速生成</span></div>
          <div className='desc-detail'>不用风吹，不用日晒，不用化妆，不用尬拍，<br />仅需2分钟，拥有写真大片！</div>
        </div>
      </div>

      <div className='text-center mt-52'>©️2023-2024 深圳我的爱智能有限公司 版权所有</div>
      <div className="flex justify-center mb-2">
        <img src={icp} />
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023094758号</a>
      </div>
    </div>
  );
}

export default App;
