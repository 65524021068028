import { Link } from 'react-router-dom';
import logo from '../../assets/c-logo.svg';
import litchi from '../../assets/logo-rect.svg';
import eb from '../../assets/eb.svg';
import icp from '../../assets/icp.png';

import './index.css';

export default function () {
  return (
    <div className='text-[#333333]'>
      <div className="bg-[#15CC93] flex justify-around items-center">
        <div className='flex items-center'>
          <img src={logo} className="h-[35px] m-4 inline" />
          <span className='text-[30px] '>我的AI</span>
        </div>
        <span className="w-40 mr-40 inline-flex justify-between">
          <span><Link to='/'>首页</Link></span>
          <span><Link to='/about'>关于我们</Link></span>
        </span>
      </div>

      <div>
        <img className='w-full' src="images/banner.jpg" />
      </div>

      <div className='mt-[55px]'>
        <div className='flex justify-center text-[58px]'>关于<span className='text-[#15CC93]'>我的AI</span></div>
        <div className='flex justify-center'>
          <pre className='w-4/5 whitespace-pre-wrap'>
            {`
    深圳市我的爱智能有限公司始建于2023年，是一家秉持前沿科技理念的企业，专注于先进的人工智能AIGC技术的发展和应用的公司。
    我们的使命是“真正让AI技术普惠大众”，我们不懈努力于研发更智能、更创新的AIGC产品，致力于让图片、视频、设计的创作过程变得更加轻松、直观。
          
    《荔枝相机》和《我的AI电商设计平台》皆为深圳市我的爱智能有限公司研发的AI应用，它们代表着我们在科技前沿的探索和突破。通过深入挖掘人工智能的潜能，我们为用户提供卓越的工具，让创意得以释放。在这个充满活力和变革的时代，我们公司将持续引领AIGC产品的创新，助力用户在数字创意领域中蓬勃发展。

    未来展望：我们将不断探索，进一步拓展在视频和游戏领域的AI应用。通过持续创新，我们致力于为用户提供更多独特、高效的工具，以推动数字创意的前沿发展，让人工智能在多个领域发挥更广泛的作用。
          `}
          </pre>
        </div>
      </div>

      <div className='mt-[43px]'>
        <div className='flex justify-center text-[58px]'>旗下<span className='text-[#15CC93]'>AI应用</span></div>
        <div className='flex justify-around pl-24 pr-24 mt-[43px]'>
          <Link to='/'>
            <div className='product-box box-red'>
              <div className='flex items-center'>
                <div className='w-[112px] h-[112px] bg-white rounded-[30px] flex justify-center items-center inline-block'>
                  <img src={litchi} />
                </div>
                <span className='ml-[25px] text-[38px]'>荔枝相机</span>
              </div>
              <pre className='mt-[26px] whitespace-pre-wrap text-[16px]'>
                {`
荔枝相机，一款领先的互联网应用，融合AI生成图像和换脸技术，只需要用户提供一张头像照片，即可为用户生成精美的影楼级高端写真照片。
轻松创作个性头像、有趣的照片，满足您对美的追求，让您的相片变得更有趣、更独特。
          `}
              </pre>
            </div>
          </Link>
          <div className='product-box box-green'>
            <div className='flex items-center'>
              <div className='w-[112px] h-[112px] bg-white rounded-[30px] flex justify-center items-center inline-block'>
                <img src={eb} />
              </div>
              <span className='ml-[25px] text-[38px]'>我的AI电商设计平台</span>
            </div>
            <pre className='mt-[26px] whitespace-pre-wrap text-[16px]'>
              {`
我的AI电商设计平台，助您轻松打造引人注目的电商广告。通过先进的人工智能技术，我们提供快捷而高效的图片生成服务，让您在电商运营中脱颖而出。
不论您是小型企业还是个体创业者，我们的平台将为您提供个性化、专业水准的广告素材，助力您在竞争激烈的市场中脱颖而出。
          `}
            </pre>
          </div>
        </div>
      </div>


      <div className='text-center mt-52'>©️2023-2024 深圳我的爱智能有限公司 版权所有</div>
      <div className="flex justify-center mb-2">
        <img src={icp} />
        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2023094758号</a>
      </div>
    </div>
  )
}