import {
  HashRouter as Router,
  Route,
  Routes,
} from 'react-router-dom'
import Litchi from './pages/litchi'
import About from './pages/home'

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" Component={Litchi} />
          <Route path="/about" Component={About} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;